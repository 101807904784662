<template>
  <b-card no-body>
    <!--<b-card-text>{{selected}}</b-card-text>-->
    <b-row>
      <b-col md="6">
        <b-button
          v-if="$hascan('convocatoria.registrar')"
          variant="primary"
          class="mr-1"
          :to="{ name: 'convocatoria-nuevo' }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Nuevo</span>
        </b-button>

        <b-button
          @click="actualizarLista()"
          variant="secondary"
          class="btn-icon mr-1"
        >
          <feather-icon icon="RotateCcwIcon" />
        </b-button>
      </b-col>
      <b-col md="3">
        <!--<v-select
          label="title"
          placeholder="Filtrar por categoria"
          v-model="categoria_id"
          :options="categoriaOpciones"
        />-->
      </b-col>
      <b-col md="3">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- <b-col md="3">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col> -->

      <b-col cols="12" class="my-2">
        <b-overlay :show="loading">
          <b-table
            class="position-relative sm"
            responsive="sm"
            ref="selectableTable"
            selectable
            select-mode="single"
            hover
            striped
            :small="true"
            :items="items"
            :fields="fields"
            @row-selected="onRowSelected"
            show-empty
            empty-text="No se encontraron registros coincidentes"
            @filtered="onFiltered"            
            
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>

            <template #cell(titulo)="data">
              <h5>
                <strong>{{ data.value }}</strong>
              </h5>
              <strong class="text-primary">Url: </strong
              >{{ data.item.url }}
            </template>

            <template #cell(estado)="data">
              <b-badge :variant="estado[1][data.value]">
                {{ estado[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(accion)="data">
              <div class="text-nowrap">
                <b-button
                    v-if="$hascan('convocatoria.restaurar')"
                    @click="confirmar_restaurar(data.item.entrada_id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-b-tooltip.hover.bottom="'Restaurar'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="RepeatIcon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-dark"
                    v-b-tooltip.hover.bottom="'Previsualizar'"
                    class="btn-icon mr-1"
                    target="_blank"
                    size="sm"
                    :href="`${mixing.app_url}convocatoria/${data.item.url}?preview=${data.item.sha}`"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>

                  <b-button
                    v-if="$hascan('convocatoria.eliminar')"
                    @click="confirmar_eliminar(data.item.entrada_id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    v-b-tooltip.hover.bottom="'Borrar Permanente'"
                    class="btn-icon"
                    size="sm"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>

                <!-- <b-button
                  v-if="$hascan('convocatoria.editar')"
                  :to="{
                    name: 'convocatoria-editar',
                    params: { id: data.item.entrada_id },
                  }"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.bottom="'Editar'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-dark"
                  @click="imprimirEntradaPdf(data.item.entrada_id)"
                  v-b-tooltip.hover.bottom="'Imprimir PDF'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-dark"
                  v-b-tooltip.hover.bottom="'Previsualizar'"
                  class="btn-icon mr-1"
                  target="_blank"
                  size="sm"
                  :href="`${mixing.app_url}convocatoria/${data.item.url}?preview=${data.item.sha}`"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>

                <b-button
                  v-if="$hascan('convocatoria.papelera')"
                  @click="confirmar_mover_papelera(data.item.entrada_id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Papelera'"
                  class="btn-icon"
                  size="sm"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button> -->
              </div>
            </template>

            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
//import axios from 'axios'
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "published_at", label: "Fecha", sortable: false },
        { key: "estado", label: "Estado", sortable: false },
        { key: "titulo", label: "Titulo", sortable: false },
        { key: "fecha_inicio", label: "Fecha Inicio", sortable: false },
        { key: "fecha_final", label: "Fecha Final", sortable: false },
        // { key: 'user.name', label: 'Autor', sortable: false },
        //{ key: 'categoria.nombre', label: 'Categoria', sortable: false },

        { key: "created_at", label: "Creado", sortable: false },
        "accion",
      ],
      selected: [],
      filter: "",
      filterOn: [],
      perPage: 2,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      loading: false,
      estado: [
        {
          1: "Publicado",
          2: "Borrador",
          3: "Papelera",
        },
        {
          1: "light-primary",
          2: "light-warning",
          3: "light-danger",
        },
      ],
      item: [],
      categoria_id: "",
      categoriaOpciones: [],
    };
  },
  created() {
    this.loading = true;
    this.listar();
    //this.listarCategorias();
  },
  watch: {
    categoria_id: function () {
      this.loading = true;
      this.listar();
    },
    currentPage: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    filter: function (val) {
      if (val == "" || val.length == 0) {
        this.loading = true;
        this.listar();
      }
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2);
    },
    onFiltered(filteredItems) {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.loading = true;
        this.listar();
      }
    },
    listar() {
      let categoria = "";
      if (
        this.categoria_id == null ||
        this.categoria_id == "" ||
        this.categoria_id == "undefined"
      ) {
        categoria = "";
      } else {
        categoria = this.categoria_id.value;
      }
      var url =
        "/convocatorias/ver/papelera?page=" +
        this.currentPage +
        "&categoria=" +
        categoria +
        "&buscar=" +
        this.filter;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          //console.log("hola mundo");
          //this.currentPage=res.data.current_page
          this.totalRows = res.data.total;
          this.loading = false;
          // this.items=res.data.data.data;
          // this.totalRows = this.items.length
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    actualizarLista() {
      this.loading = true;
      this.listar();
    },
    listarCategorias() {
      var url = "/admin/categorias";
      axiosIns
        .get(url)
        .then((res) => {
          this.categoriaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    confirmar_restaurar(id){
          this.$swal({
            title: "Advertencia",
            text: "Confirme que esta seguro de restaurar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.restaurar(id);
            }
          });
        },
        restaurar(id){
          axiosIns.put('/admin/entradas/restaurar/'+id)
          .then(res => {
              this.$bvToast.toast('Exito', {
                title: 'Se restauro correctamente',
                variant:'success',
                solid: false,
              })
              this.actualizarLista();
          }) 
          .catch(err =>{
              console.log(err);
          });
        },
        confirmar_eliminar(id) {
          this.$swal({
            title: "Advertencia",
            text: "¿Estás seguro de que deseas eliminar esta publicación de forma permanente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-danger",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.eliminar(id);
            }
          });
        },
        eliminar(id) {
         axiosIns.delete('/convocatorias/'+id)
          .then(res => {
              this.$bvToast.toast('Exito', {
                title: 'Se elimino correctamente',
                variant:'success',
                solid: false,
              })
              this.actualizarLista();
          }) 
          .catch(err =>{
              console.log(err);
          });
        },


    //imprimir pdf
    
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>