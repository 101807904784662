<template>
  <b-card title="Convocatorias">
    <b-tabs>
      <b-tab lazy title="Publicado">
        <Publicado/>
      </b-tab>
      <b-tab lazy title="Borradores">
        <Borrador/>
      </b-tab>
      <b-tab lazy title="Programado">
        <Programado/>
      </b-tab>
      <b-tab lazy title="Papelera">
        <Papelera/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import Publicado from "@/views/aplicaciones/convocatorias/tabs/Publicado.vue";
import Borrador from "@/views/aplicaciones/convocatorias/tabs/Borrador.vue";
import Programado from "@/views/aplicaciones/convocatorias/tabs/Programado.vue";
import Papelera from "@/views/aplicaciones/convocatorias/tabs/Papelera.vue";

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    Publicado,
    Borrador,
    Programado,
    Papelera
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    };
  },
  created() {},
  watch: {
  },
  methods: {
    
  },
};
</script>
<style lang="scss"></style>
